@import "../src/assets/css/colors.css";
@import "../src/assets/css/size.css";
@import "../src/assets/css/string.css";
@import "../src/assets/css/custom.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

@layer base {
  .e-filemanager .e-splitter .e-split-bar.e-split-bar-horizontal {
    background: #ecf3fe;
  }

  .e-filemanager .e-toolbar {
    border-bottom-color: #ecf3fe;
  }

  .e-filemanager,
  .e-filemanager .e-grid .e-gridheader,
  .e-filemanager .e-grid .e-headercontent {
    border-color: #ecf3fe;
  }

  .e-filemanager .e-headercell {
    background: linear-gradient(138.92deg, #ecf3fe 17.44%, rgb(236 243 254 / 15%) 105.36%) !important;
  }
}

/* source-sans-pro-200 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/source-sans-pro-v19-latin-200.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./assets/fonts/source-sans-pro-v19-latin-200.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/source-sans-pro-v19-latin-200.woff2") format("woff2"),
    url("./assets/fonts/source-sans-pro-v19-latin-200.woff") format("woff"),
    url("./assets/fonts/source-sans-pro-v19-latin-200.ttf") format("truetype"),
    url("./assets/fonts/source-sans-pro-v19-latin-200.svg#SourceSansPro")
    format("svg"); /* Legacy iOS */
}

/* source-sans-pro-200italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 200;
  src: url("./assets/fonts/source-sans-pro-v19-latin-200italic.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./assets/fonts/source-sans-pro-v19-latin-200italic.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/source-sans-pro-v19-latin-200italic.woff2")
    format("woff2"),
    url("./assets/fonts/source-sans-pro-v19-latin-200italic.woff")
    format("woff"),
    url("./assets/fonts/source-sans-pro-v19-latin-200italic.ttf")
    format("truetype"),
    url("./assets/fonts/source-sans-pro-v19-latin-200italic.svg#SourceSansPro")
    format("svg"); /* Legacy iOS */
}

/* source-sans-pro-300 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/source-sans-pro-v19-latin-300.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./assets/fonts/source-sans-pro-v19-latin-300.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/source-sans-pro-v19-latin-300.woff2") format("woff2"),
    url("./assets/fonts/source-sans-pro-v19-latin-300.woff") format("woff"),
    url("./assets/fonts/source-sans-pro-v19-latin-300.ttf") format("truetype"),
    url("./assets/fonts/source-sans-pro-v19-latin-300.svg#SourceSansPro")
    format("svg"); /* Legacy iOS */
}

/* source-sans-pro-300italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 300;
  src: url("./assets/fonts/source-sans-pro-v19-latin-300italic.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./assets/fonts/source-sans-pro-v19-latin-300italic.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/source-sans-pro-v19-latin-300italic.woff2")
    format("woff2"),
    url("./assets/fonts/source-sans-pro-v19-latin-300italic.woff")
    format("woff"),
    url("./assets/fonts/source-sans-pro-v19-latin-300italic.ttf")
    format("truetype"),
    url("./assets/fonts/source-sans-pro-v19-latin-300italic.svg#SourceSansPro")
    format("svg"); /* Legacy iOS */
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/source-sans-pro-v19-latin-regular.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./assets/fonts/source-sans-pro-v19-latin-regular.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/source-sans-pro-v19-latin-regular.woff2")
    format("woff2"),
    url("./assets/fonts/source-sans-pro-v19-latin-regular.woff") format("woff"),
    url("./assets/fonts/source-sans-pro-v19-latin-regular.ttf")
    format("truetype"),
    url("./assets/fonts/source-sans-pro-v19-latin-regular.svg#SourceSansPro")
    format("svg"); /* Legacy iOS */
}

/* source-sans-pro-italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/source-sans-pro-v19-latin-italic.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./assets/fonts/source-sans-pro-v19-latin-italic.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/source-sans-pro-v19-latin-italic.woff2") format("woff2"),
    url("./assets/fonts/source-sans-pro-v19-latin-italic.woff") format("woff"),
    url("./assets/fonts/source-sans-pro-v19-latin-italic.ttf")
    format("truetype"),
    url("./assets/fonts/source-sans-pro-v19-latin-italic.svg#SourceSansPro")
    format("svg"); /* Legacy iOS */
}

/* source-sans-pro-600 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/source-sans-pro-v19-latin-600.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./assets/fonts/source-sans-pro-v19-latin-600.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/source-sans-pro-v19-latin-600.woff2") format("woff2"),
    url("./assets/fonts/source-sans-pro-v19-latin-600.woff") format("woff"),
    url("./assets/fonts/source-sans-pro-v19-latin-600.ttf") format("truetype"),
    url("./assets/fonts/source-sans-pro-v19-latin-600.svg#SourceSansPro")
    format("svg"); /* Legacy iOS */
}

/* source-sans-pro-600italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  src: url("./assets/fonts/source-sans-pro-v19-latin-600italic.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./assets/fonts/source-sans-pro-v19-latin-600italic.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/source-sans-pro-v19-latin-600italic.woff2")
    format("woff2"),
    url("./assets/fonts/source-sans-pro-v19-latin-600italic.woff")
    format("woff"),
    url("./assets/fonts/source-sans-pro-v19-latin-600italic.ttf")
    format("truetype"),
    url("./assets/fonts/source-sans-pro-v19-latin-600italic.svg#SourceSansPro")
    format("svg"); /* Legacy iOS */
}

/* source-sans-pro-700 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/source-sans-pro-v19-latin-700.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./assets/fonts/source-sans-pro-v19-latin-700.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/source-sans-pro-v19-latin-700.woff2") format("woff2"),
    url("./assets/fonts/source-sans-pro-v19-latin-700.woff") format("woff"),
    url("./assets/fonts/source-sans-pro-v19-latin-700.ttf") format("truetype"),
    url("./assets/fonts/source-sans-pro-v19-latin-700.svg#SourceSansPro")
    format("svg"); /* Legacy iOS */
}

/* source-sans-pro-700italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/source-sans-pro-v19-latin-700italic.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./assets/fonts/source-sans-pro-v19-latin-700italic.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/source-sans-pro-v19-latin-700italic.woff2")
    format("woff2"),
    url("./assets/fonts/source-sans-pro-v19-latin-700italic.woff")
    format("woff"),
    url("./assets/fonts/source-sans-pro-v19-latin-700italic.ttf")
    format("truetype"),
    url("./assets/fonts/source-sans-pro-v19-latin-700italic.svg#SourceSansPro")
    format("svg"); /* Legacy iOS */
}

/* source-sans-pro-900 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/source-sans-pro-v19-latin-900.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./assets/fonts/source-sans-pro-v19-latin-900.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/source-sans-pro-v19-latin-900.woff2") format("woff2"),
    url("./assets/fonts/source-sans-pro-v19-latin-900.woff") format("woff"),
    url("./assets/fonts/source-sans-pro-v19-latin-900.ttf") format("truetype"),
    url("./assets/fonts/source-sans-pro-v19-latin-900.svg#SourceSansPro")
    format("svg"); /* Legacy iOS */
}

/* source-sans-pro-900italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 900;
  src: url("./assets/fonts/source-sans-pro-v19-latin-900italic.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./assets/fonts/source-sans-pro-v19-latin-900italic.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/source-sans-pro-v19-latin-900italic.woff2")
    format("woff2"),
    url("./assets/fonts/source-sans-pro-v19-latin-900italic.woff")
    format("woff"),
    url("./assets/fonts/source-sans-pro-v19-latin-900italic.ttf")
    format("truetype"),
    url("./assets/fonts/source-sans-pro-v19-latin-900italic.svg#SourceSansPro")
    format("svg"); /* Legacy iOS */
}

.e-dlg-overlay {
  background: var(--background-scrim) !important;
  opacity: 1;
}

.e-gantt-dialog .e-dlg-header-content {
  background-image: linear-gradient(95deg, var(--system-colour-41) 0%, var(--system-colour-41) 100%);
}

.e-gantt-dialog .e-tab .e-tab-header {
  background-image: linear-gradient(95deg, var(--system-colour-41) 0%, var(--system-colour-41) 100%);
}

.e-gantt-dialog .e-tab .e-tab-header .e-toolbar-item:not(.e-active) .e-tab-wrap:hover .e-tab-text {
  color: black;
}

.e-gantt-dialog .e-dlg-header {
  font-size: 24px;
  font-weight: 400;
  color: black;
}

.e-dialog .e-btn .e-btn-icon.e-icon-dlg-close {
  color: var(--black-text-colour);
}

.e-gantt-dialog .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-tab-text {
  color: var(--black-text-colour);
}

.e-gantt-dialog .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-text {
  color: var(--black-text-colour);
}

.e-gantt-dialog .e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap::before {
  width: 100%;
  min-width: max-content;
  height: var(--size-4);
  border-radius: var(--size-6);
  background-image: linear-gradient(95deg, var(--gradient-color) 0%, var(--primary-gradient-color2) 110%);
  border: none;
  top: 90%;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  background-image: linear-gradient(119deg, var(--gradient-color) 0%, var(--primary-gradient-color2) 111%);
  border: none;
  border-radius: var(--size-5);
}

.e-gantt-dialog > .e-dlg-content {
  overflow: hidden;
}

.e-btn.e-flat,
.e-css.e-btn.e-flat {
  border-radius: var(--size-5);
}

/* TODO: temporoy css for querybuilder */
.e-rule-filter {
  width: 280px !important;
}

/* For Fixing Google SignIN Default Button flickring issue */
#googleLoginButton > div > div:first-child {
  display: none;
}

#googleLoginButton > div > div:last-child {
  display: none;
}

.ReactVirtualized__Grid__innerScrollContainer { overflow: initial !important; }
.ReactVirtualized__Grid__innerScrollContainer > div { width: 100% !important; }

.e-hide {
  display: none !important;
}

/* Freshchat integration */
#fc_frame {
  display: none;
}

/* can be removed once syncfusion fixes grouping bug in datagrid - databank */
.e-groupfooterrow.e-summaryrow {
  display: none;
}

@keyframes flash {
  0% {
    opacity: 0;
  }
}

@keyframes converge {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }

  100% {
    transform: translate(var(--converge-x), var(--converge-y));
    opacity: 1;
    width: var(--final-width);
    height: var(--final-height);
    top: 0;
    left: 0;
  }
}

.animate-converge {
  animation: converge 1.5s forwards;
}

.animate-flash {
  animation: flash 0.5s forwards;
}

.e-childrow-hidden {
  display: none;
}

.mdi-filter-variant {
  --mdi-filter-variant-svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Efilter-variant%3C/title%3E%3Cpath d='M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z' /%3E%3C/svg%3E");

  -webkit-mask: var(--mdi-filter-variant-svg) no-repeat;
  mask: var(--mdi-filter-variant-svg) no-repeat;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
}
