.cube-slick-grid .slickgrid-container .slick-row.odd {
  background: none !important;
}

.cube-slick-grid .slickgrid-container .grid-canvas .slick-cell {
  display: flex;
}

.cube-slick-grid .slickgrid-container .slick-row:hover {
  background: var(--system-colour-46) !important;
}

.cube-slick-grid .slickgrid-container .grid-canvas .slick-cell.selected {
  background-color: var(--primary-blue) !important;
}

.cube-slick-grid .slickgrid-container .slick-header-columns .slick-header-column.slick-state-default {
  background-color: var(--surface-gradient-color2) !important;
  background-image: none !important;
}

.cube-slick-grid .slickgrid-container .slick-pane-top {
  border: none !important;
}

.cube-slick-grid .slickgrid-container {
  border: none !important;
}

.cube-slick-grid .slick-preheader-panel .slick-dropzone,
.cube-slick-grid .slick-preheader-panel .slick-dropzone-hover,
.cube-slick-grid .slick-topheader-panel .slick-dropzone,
.cube-slick-grid .slick-topheader-panel .slick-dropzone-hover {
  border: none !important;
}

.cube-slick-grid .slick-cell-checkboxsel .icon-checkbox-container div.mdi,
.cube-slick-grid .slick-column-name .icon-checkbox-container div.mdi,
.cube-slick-grid .slick-headerrow-column.checkbox-header .icon-checkbox-container div.mdi {
  background: linear-gradient(100.44deg, var(--primary-gradient-color1) 0%, var(--primary-gradient-color2) 110.4%) !important;
}

.cube-slick-grid .slickgrid-container .slick-row {
  border-bottom: 1px solid var(--system-colour-44) !important;
}

.cube-slick-grid .slickgrid-container .slick-cell {
  /* border-right: 1px solid var(--system-colour-44) !important; */
}

.cube-slick-grid .slickgrid-container .slick-group-toggle {
  /* border-right: 1px solid var(--system-colour-44) !important; */
}

.search-filter input,
input.search-filter {
  font-family: "Source Sans Pro" !important;
}

.cube-slick-grid .slickgrid-container .slick-reorder-guide {
  background: var(--primary-gradient-color1) !important;
  display: inline-block;
  height: var(--slick-row-move-plugin-guide-height, 2px);
  opacity: var(--slick-row-move-plugin-guide-opacity, 0.7);
  position: relative;
}

.cube-slick-grid .slickgrid-container .slick-reorder-guide::after {
  content: "";
  position: absolute;
  left: 0;
  top: -48px;
  width: 100%;
  height: var(--slick-row-move-plugin-guide-height, 2px);
  background: var(--primary-gradient-color1);
  opacity: var(--slick-row-move-plugin-guide-opacity, 0.7);
}

.cube-slick-grid .slickgrid-container .grid-canvas .slick-cell .slick-group-toggle {
  color: #333;
}

.cube-slick-grid .slick-preheader-panel .slick-dropzone .slick-group-toggle-all .slick-group-toggle-all-icon,
.slick-preheader-panel .slick-dropzone-hover .slick-group-toggle-all .slick-group-toggle-all-icon,
.slick-topheader-panel .slick-dropzone .slick-group-toggle-all .slick-group-toggle-all-icon,
.slick-topheader-panel .slick-dropzone-hover .slick-group-toggle-all .slick-group-toggle-all-icon {
  color: #333;
}

.cube-slick-grid .slickgrid-container .slick-preheader-panel.slick-state-default .slick-header-columns .slick-header-column,
.slickgrid-container .slick-topheader-panel.slick-state-default .slick-header-columns .slick-header-column {
  border-right-width: 0 !important;
}

.cube-slick-grid .slick-row .dynamic-cell-detail {
  background-color: white;
  border: none;
}

.detail-view-toggle.expand::before {
  content: "\25B6";
  font-size: 10px;
  color: black;
  margin-right: 5px;
}

.detail-view-toggle.collapse::before {
  content: "\25BC";
  font-size: 10px;
  color: black;
  margin-right: 5px;
}

.cube-slick-grid .slick-row .detail-view-toggle.expand {
  color: inherit;
  background-color: transparent;

  --slick-detail-view-icon-expand-svg: none;

  -webkit-mask: var(--slick-detail-view-icon-expand-svg) no-repeat;
  mask: var(--slick-detail-view-icon-expand-svg) no-repeat;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
}

.cube-slick-grid .slick-row .detail-view-toggle.collapse {
  color: none;

  --slick-detail-view-icon-expand-svg: none;

  -webkit-mask: var(--slick-detail-view-icon-expand-svg) no-repeat;
  mask: var(--slick-detail-view-icon-expand-svg) no-repeat;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
}
